import { defineStore } from "pinia";
import usStatesList from "@/assets/json/us-states-list.json";

export interface AppDataStoreState {
  locations: PMC.Parking.Location[];
  partnerId: string;
}

export const useAppDataStore = defineStore({
  id: "appData",
  state: (): AppDataStoreState => {
    return {
      locations: [],
      partnerId: "",
    };
  },
  getters: {
    usStates() {
      return usStatesList;
    },
  },
  actions: {
    setPartnerId(partnerId: string) {
      this.partnerId = partnerId;
    },
    resetStore() {
      this.$reset();
    },
  },
});
