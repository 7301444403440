import { Tooltip } from "bootstrap";

// There is a bug which causes the tooltips to continue to display after a
// button is clicked. Here, we cache all tooltips and hide them on a click event
// TODO: is this the best way to handle?
const _tips = [];

export const tooltip = {
  mounted(el) {
    _tips.push(
      new Tooltip(el, {
        boundary: "window",
        trigger: "hover manual",
      }),
    );

    // Close all tooltips on click events.
    window.addEventListener("click", () => {
      _tips.forEach((tt) => tt.hide());
    });
  },
};
