import type { PiniaPluginContext } from "pinia";
import type { AppUserStoreUser } from "@/stores/app-user";

const LS_APP_KEY = "partnerPortal";

export default function PersistAppUserStorePlugin(context: PiniaPluginContext) {
  if (context.store.$id !== "appUser") {
    return {};
  }

  const user = JSON.parse(
    localStorage.getItem(`${LS_APP_KEY}:user`) || "{}",
  ) as AppUserStoreUser;

  if (user?.attributes) {
    context.store.user = user;

    const accessToken = localStorage.getItem(`${LS_APP_KEY}:accessToken`);
    const refreshToken = localStorage.getItem(`${LS_APP_KEY}:refreshToken`);

    context.store.accessToken = accessToken;
    context.store.refreshToken = refreshToken;
  }

  context.store.$onAction((event) => {
    if (event.name === "setUserAndTokens") {
      const [user, accessToken, refreshToken] = event.args as [
        AppUserStoreUser,
        string,
        string,
      ];

      localStorage.setItem(`${LS_APP_KEY}:user`, JSON.stringify(user));
      localStorage.setItem(`${LS_APP_KEY}:accessToken`, accessToken);
      localStorage.setItem(`${LS_APP_KEY}:refreshToken`, refreshToken);
    }

    if (event.name === "signOut") {
      localStorage.removeItem(`${LS_APP_KEY}:user`);
      localStorage.removeItem(`${LS_APP_KEY}:accessToken`);
      localStorage.removeItem(`${LS_APP_KEY}:refreshToken`);
    }
  });

  return {};
}
