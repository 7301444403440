import type { RouteLocationNormalized } from "vue-router";
import { useAppUserStore } from "@/stores/app-user";

export default function authGuard(to: RouteLocationNormalized) {
  const appUserStore = useAppUserStore();

  if (to.name === "Login") {
    appUserStore.signOut();

    return;
  }

  if (!appUserStore.isAuthenticated && to.name !== "Login") {
    return { name: "Login" };
  }
}
