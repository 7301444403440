<template>
  <a
    href="/logout"
    class="dropdown-item d-flex align-items-center"
    @click.prevent="signOut"
    >Log Out <i class="fa fa-toggle-off fa-fw ms-auto text-gray-400 fs-16px"></i
  ></a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppUserStore } from "@/stores/app-user";
import { useAppDataStore } from "@/stores/app-data";

export default defineComponent({
  setup() {
    return {
      appUser: useAppUserStore(),
      appData: useAppDataStore(),
    };
  },
  data() {
    return {
      isSigningOut: false,
    };
  },
  methods: {
    async signOut() {
      this.isSigningOut = true;
      this.appUser.signOut();
      this.appData.resetStore();
      this.$router.push({ name: "Login" });
    },
  },
});
</script>
