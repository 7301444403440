<template>
  <a
    href="#"
    data-toggle="card-expand"
    ref="toggle"
    class="text-white text-opacity-50 text-decoration-none"
  >
    <i class="bi bi-fullscreen"></i>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tooltip } from "bootstrap";

export default defineComponent({
  mounted() {
    const toggle = this.$refs.toggle as HTMLAnchorElement;

    toggle.onclick = function (e: MouseEvent) {
      e.preventDefault();

      const target = this.closest(".card");
      const targetClass = "card-expand";

      if (
        document.body.classList.contains(targetClass) &&
        target.classList.contains(targetClass)
      ) {
        target.removeAttribute("style");
        target.classList.remove(targetClass);
        document.body.classList.remove(targetClass);
      } else {
        document.body.classList.add(targetClass);
        target.classList.add(targetClass);
      }

      window.dispatchEvent(new Event("resize"));
    };

    new Tooltip(this.$refs.toggler, {
      title: "Expand / Compress",
      placement: "bottom",
      trigger: "hover",
      container: "body",
    });
  },
});
</script>
